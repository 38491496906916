import React, { useState, useEffect } from 'react';
import mixpanel from "mixpanel-browser";
import { Button, Card, Modal } from 'react-bootstrap';
import { RestHelper } from '../helper/restHelper'

const NotaryCampaign = () => {
  const [showCard, setShowCard] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [apiRes, setApiRes] = useState()

  const api = RestHelper()
  const handleCloseCard = () => {
    setShowCard(false);
    sessionStorage.setItem('adClosed', 'true'); // Set flag in sessionStorage
    mixpanel.track('Affiliate Campaign', { 'action': 'minimize' })
  }

  const updateCampaigm = (action) => {
    const options = {}
    const passedData = {'action' : action}
    api
      .postCreate('/campaign-manage-user', options, passedData)
      .then(res => setApiRes(res))
      .catch(err => console.log(err))
  }
  const handleNotInterested = () => {
    setShowCard(false)
    updateCampaigm('Decline')
    sessionStorage.setItem('adClosed', 'true'); // Set flag in sessionStorage
    mixpanel.track('Affiliate Campaign', {'action': 'not_interested'})
  }

  const handleShowModal = () => {
    setShowModal(true);
    updateCampaigm('Enroll')
    mixpanel.track('Affiliate Campaign', {'action':'enroll'})
  }
  const handleCloseModal = () => {
    setShowModal(false);
    setShowCard(false);
    sessionStorage.setItem('adClosed', 'true'); // Set flag in sessionStorage
}

  useEffect(() => {
    mixpanel.track('Affiliate Campaign', { 'action': 'pageview' })
  }, [,])

  return (
    <>
      {showCard && (
        <Card style={ctaCardStyle}>
          <button onClick={handleCloseCard} style={closeButtonStyle}>X</button>
          <Card.Body>
            <h3 style={headingStyle}>Want to Earn More in 2025?</h3>
            <p style={paragraphStyle}>
              Announcing the Pinpoint Field Services Affiliate Program! Help us grow and 
              get rewarded for referrals.
            </p>
            <div style={buttonContainerStyle}>
              <Button variant="primary" style={buttonStyle} onClick={handleShowModal}>Learn More</Button>
              <Button variant="secondary" style={buttonStyle} onClick={handleNotInterested}>Not Interested</Button>
            </div>
          </Card.Body>
        </Card>
      )}
      {/* Modal Component */}
      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header>
          <Modal.Title>Thank You!</Modal.Title>
          <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleCloseModal}>
          <span aria-hidden="true">&times;</span>
        </button>

        </Modal.Header>
        <Modal.Body>
          Thank you for your interest! Please check your inbox for an email with more details.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const ctaCardStyle = {
    backgroundColor: '#f8f9fa',
    padding: '5px',
    borderRadius: '8px',
    boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.1)',
    border: '3px solid #007bff',  // Blue border to make it pop
    marginBottom: '20px',
    position: 'relative',
    textAlign: 'center'
  };

  const cardBodyStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  };
  
  const closeButtonStyle = {
    position: 'absolute',
    top: '10px',
    right: '10px',
    background: 'none',
    border: 'none',
    fontSize: '16px',
    cursor: 'pointer',
  };

  const buttonContainerStyle = {
    display: 'flex',
    justifyContent: 'center',
    gap: '10px',  // Add spacing between the buttons
    marginTop: '15px',
  };
  
  const buttonStyle = {
    margin: '10px',
  };
  
  const headingStyle = {
    //color: '#007bff',  // Blue to match the border
    color: '#343a40',
    fontWeight: 'bold',
  };
  
  const paragraphStyle = {
    fontSize: '16px',
    color: '#343a40',
  };

export default NotaryCampaign;
