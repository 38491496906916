import React from "react";
import DataPolicyPopup from "./data-policy-popup";

const Footer = () => (
  <footer className="bg-light p-3 text-center">
    <div className="navbar-brand mb-2" />
    <p>
      <b>@ 2025 Pinpoint Field Services, LLC  All Rights Reserved
      <br/>
      PO Box 541026
      <br/>
      Omaha, NE 68154
      <br/>
      (888) 530-7592</b>
      <br/>
      <div className='row justify-content-center'>
      <p className='fas mr-2 mx-3'>Follow us on LinkedIn!</p>
      <a href="https://www.linkedin.com/company/pinpoint-field-services" target="_blank" rel="noopener noreferrer">
      <img src="..\img\In-Blue-14@2x.png" alt="LinkedIn Logo" />
    </a>
    </div>
      Icons by <a href="https://icons8.com" target="_blank" rel="noopener noreferer">Icons8</a>
      <br/>
      <DataPolicyPopup/>
    </p>
  </footer>
);

export default Footer;
