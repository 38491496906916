import React, { useState } from "react";
import "../data-policy-popup.css"; // Updated CSS file for better styles

const DataPolicyPopup = () => {
    const [showModal, setShowModal] = useState(false);

    const toggleModal = () => {
        setShowModal(!showModal);
    };

    return (
        <div>
            {/* Data Policy Link */}
            <a href="#data-policy" className="data-policy-link" onClick={toggleModal}>
                Data Policy
            </a>

            {showModal && (
                <div className="modal-overlay" onClick={toggleModal}>
                    <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                        <div className="modal-header">
                            <h2>Data Policy</h2>
                            <button className="close-modal-button" onClick={toggleModal}>
                                &times;
                            </button>
                        </div>
                        <div className="modal-body">
                            <p>Last Updated: January 21, 2025</p>
                            <p>
                                At Pinpoint Field Services, we take your privacy seriously. This
                                data policy outlines the types of personal information we collect,
                                how we use and share that information, and your rights regarding
                                your data. By using our services, you agree to the terms outlined
                                in this policy.
                            </p>
                            <h3>1. Information We Collect</h3>
                            <ul className="info-list">
                                <li>Name</li>
                                <li>Email address</li>
                                <li>Phone number</li>
                            </ul>
                            <h3>2. How We Use Your Information</h3>
                            <p>
                                We use the information collected to:
                                <ul className="info-list">

                                    <li>Communicate with you via email and text messages.</li>
                                    <li>Notify you of new assignments and updates to existing assignments.</li>
                                    <li>Provide services and manage your interactions with third parties as necessary for completing assignments.</li>
                                </ul>
                            </p>
                            <h3>3. Data Sharing with Third Parties</h3>
                            <p>
                                We do not sell or rent your personal information to third parties. However, we may share your information with third parties only when it is necessary to facilitate the assignment process (e.g., with parties involved in your assignments). These third parties are required to protect your personal information and use it solely for the purposes of completing assignments.
                            </p>
                            <h3>4. Data Security</h3>
                            <p>
                                We are committed to ensuring the security of your personal information. All data is stored securely in the cloud and encrypted both at rest and in transit. We employ industry-standard security measures to protect your data from unauthorized access, use, or disclosure.
                            </p>
                            <h3>5. User Rights and Opt-Out</h3>
                            <p>
                                You have the right to opt out of receiving communications from us. If you wish to delete your data and stop receiving text messages, you can opt out by texting STOP to 888-530-7592. Please note that opting out of certain communications may limit your ability to receive updates related to your assignments.
                            </p>
                            <h3>6. Use of Analytics Tools</h3>
                            <p>
                                We use Mixpanel, a third-party analytics tool, to track user behavior and improve website performance. Mixpanel helps us understand how users interact with our services and make data-driven decisions to enhance your experience.
                            </p>
                            <h3>7. Changes to This Policy</h3>
                            <p>
                                We reserve the right to update this data policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. We will notify users of any significant changes by updating the date at the top of this page.
                            </p>
                            <h3>8. Contact Us</h3>
                            <p>
                                If you have any questions or concerns about this data policy or how your information is handled, please contact us at:
                                <ul>
                                    <li>Pinpoint Field Services</li>
                                    <li>Email: orders@pinpointfieldservices.com</li>
                                    <li>Phone: 888-530-7592</li>
                                </ul>
                            </p>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default DataPolicyPopup;

