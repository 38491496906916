// src/components/originator-pending.js

import React, { useState, Suspense, useEffect, Fragment } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { RestHelper } from '../../helper/restHelper';
import { AdminEditCreatedRequest, AdminEditSelectChoice, AdminAssignDirect, AdminApptNotesHandler, AdminUploadStatus, AdminEditStatus } from "../../components/admin";
import AdminPickNewNotary from './admin-pick-new-notary';
import { PopupOverlay, GeneralUploadStatus, ProfilePopupOverlay, GeneralGroupFileUpload } from '../../components';




const AdminViewRequest = (data) => {

  const [requestDetails, setRequestDetails] = useState([])
  const [isLoaded, setIsLoaded] = useState(false)
  const [showDetails, setShowDetails] = useState(true)
  const [showResponse, setShowResponse] = useState(false)
  const [updateResponse, setUpdateResponse] = useState([])
  const [uploadInfo, setUploadInfo] = useState({})
  const [uploadResponse, setUploadResponse] = useState()
  const [file, setFile] = useState()
  const [fileLoaded, setFileLoaded] = useState(false)
  const [showFiles, setShowFiles] = useState(false)
  const [showFileName, setShowFileName] = useState(true)
  const [isMounted, setIsMounted] = useState(false)
  const [isPending, setIsPending] = useState(false)
  const [isScheduled, setIsScheduled] = useState(false)
  const [editScreen, setEditScreen] = useState(false)
  const [editNotary, setEditNotary] = useState(false)
  const [editStatus, setEditStatus] = useState(false)
  const [assignDirect, setAssignDirect] = useState(false)
  const [editDetails, setEditDetails] = useState(false)
  const [requestId, setRequestId] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [showPopup, setShowPopup] = useState(false)
  const [showNotaryPopup, setShowNotaryPopup] = useState(false)
  const [profileEmail, setProfileEmail] = useState('')
  const [passedMessage, setPassedMessage] = useState('')
  const [passedObject, setPassedObject] = useState('')
  const api = RestHelper()

  useEffect(() => {
    if (fileLoaded) {
      uploadHandler()
    }
  }, [file, setFile])

  const fileChangedHandler = event => {
    setFileLoaded(true)
    setFile(event.target.files[0])
    setShowFileName(false)
  }
  const uploadHandler = () => {
    const options = {}
    setIsLoading(true)
    setFileLoaded(false)
    const fileData = { 'unique_id': data.requestId, 'file_name': file.name }
    api
      .postCreate('/upload-file-admin', options, fileData)
      .then(res => setUploadInfo(res))
      .catch(err => console.log(err))
  }

  const getInvoice = (e) => {
    const options = {}
    const fileData = { 'unique_id': data.requestId, 'file_name': e }
    api
      .postCreate('/get-invoice-file', options, fileData)
      .then(res => window.open(res.get_url, "_blank"))
      .catch(err => console.log(err))
  }

  function handleMessage(value) {
    if (value === 'Details') {
      setEditScreen(false)
      setEditDetails(true)
    }
    if (value === 'Notary') {
      console.log((requestDetails.assigned_notary))
      setEditScreen(false)
      setEditNotary(true)
    }
    if (value === 'Status') {
      setEditScreen(false)
      setEditStatus(true)
    }
    if (value === 'DirectAssign') {
      setEditScreen(false)
      setAssignDirect(true)
    }
  }

  const scanBackCheck = (checkFlag) => {
    var scanBackValue = ''
    if (checkFlag === 'true') {
      scanBackValue = 'SCAN BACKS ARE REQUIRED'
    }
    else {
      scanBackValue = 'NONE'
    }
    return scanBackValue
  }

  function setTransactionType(setValue) {
    if (setValue === '0') {
      return 'Not Defined'
    }
    else if (setValue === '1') {
      return 'Purchase'
    }
    else if (setValue === '2') {
      return 'Refinance'
    }
    else if (setValue === '3') {
      return 'Sellers Deed Set'
    }
    else if (setValue === '4') {
      return 'Other'
    }
    else {
      return 'Not Defined'
    }
  }

  function setFinanceType(setValue) {
    if (setValue === '10') {
      return 'Not Defined'
    }
    else if (setValue === '11') {
      return 'FHA'
    }
    else if (setValue === '12') {
      return 'Conventional'
    }
    else if (setValue === '13') {
      return 'Reverse'
    }
    else if (setValue === '14') {
      return 'HELOC'
    }
    else if (setValue === '15') {
      return 'Commercial'
    }
    else if (setValue === '16') {
      return 'VA'
    }
    else {
      return 'Not Defined'
    }
  }

  function formatPhoneNumber(phoneNumber) {
    // Remove any non-digit characters from the phone number
    var cleaned = phoneNumber.replace(/\D/g, '');
    var len = cleaned.length
    if (len > 10) {
      var temp = cleaned.substring(0, 10)
      cleaned = temp
    }
    if (len < 10) {
      while (cleaned.length < 10) {
        cleaned += 9
      }
    }

    // Format the cleaned phone number as (123) 456-7890
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }

  useEffect(() => {
    if (isMounted) {
      const formData = new FormData()
      const key = uploadInfo.fields['key']
      const keyId = uploadInfo.fields['AWSAccessKeyId']
      const policy = uploadInfo.fields['policy']
      const token = uploadInfo.fields['x-amz-security-token']
      const signature = uploadInfo.fields['signature']
      formData.append('key', key)
      formData.append('AWSAccessKeyId', keyId)
      formData.append('policy', policy)
      formData.append('x-amz-security-token', token)
      formData.append('signature', signature)
      formData.append('file', file)
      api
        .fileCreate(uploadInfo.url, formData)
        .then(res => setUploadResponse(res))
        .catch(err => console.log(err))
      setShowFileName(true)
      setShowFiles(false)
      sleep(7000).then(() => { clearResponseMessage() });
    } else {
      setIsMounted(true)
    }
  }, [uploadInfo, setUploadInfo])

  const reqData = { data }
  const options = {}
  if (!isLoaded) {
    api
      .postCreate('/get-request-details', options, reqData)
      .then(res => initialLoad(res))
      .catch(err => console.log(err))
    setIsLoaded(true)
  }

  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  const dateFormat = (inputDate) => {
    const dateArray = inputDate.split("-")
    const date = dateArray[1] + '/' + dateArray[2] + '/' + dateArray[0]
    return date
  }
  const initialLoad = (res) => {
    setRequestDetails(res)
    if (res[0].apptFilled) {
      setIsScheduled(true)
    }
    if (!res[0].apptFilled) {
      setIsPending(true)
    }
  }
  const getTempUrl = (e) => {
    const options = {}
    const fileData = { 'unique_id': data.requestId, 'file_name': e }
    api
      .postCreate('/get-stored-file', options, fileData)
      .then(res => window.open(res.get_url, "_blank"))
      .catch(err => console.log(err))
  }


  const getTempUrlCurrentFile = () => {
    const options = {}
    const fileData = { 'unique_id': data.requestId, 'file_name': file.name }
    api
      .postCreate('/get-stored-file', options, fileData)
      .then(res => window.open(res.get_url, "_blank"))
      .catch(err => console.log(err))
  }

  function convertToSimpleDateMessage(updatedTime) {
    const date = new Date(updatedTime * 1000)
    const dateString = date.toLocaleString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    })
    return dateString
  }

  function clearResponseMessage() {
    setFile('')
    setIsLoading(false)
    setShowDetails(true)
    setShowResponse(false)
    const reqData = { data }
    const options = {}
    api
      .postCreate('/get-request-details', options, reqData)
      .then(res => initialLoad(res))
      .catch(err => console.log(err))
  }

  function showEditView(request) {
    console.log({ requestDetails })
    setShowDetails(false)
    setRequestId(request)
    setEditScreen(true)
  }

  function clearModal() {
    setShowPopup(false)
    setShowNotaryPopup(false)
  }
  function callDeleteFileService(inputData) {
    const reqData = { 'action': 'deleteFile', 'request_id': data.requestId, 'fileName': inputData }
    const options = {}
    api
      .postCreate('/manage-appts-admin', options, reqData)
      .then(res => clearResponseMessage())
      .catch(err => console.log(err))
  }

  function deleteFile(e) {
    //callDeleteFileService(e)
    var messageToSend = 'Are you sure you wish to delete ' + e + '?'
    setPassedMessage(messageToSend)
    setPassedObject(e)
    setShowPopup(true)
    const btnRef = document.getElementById(e)
    btnRef.blur()
    console.log(e)
  }

  function getProfile(e) {
    setProfileEmail(e)
    setShowNotaryPopup(true)
  }

  function confirmAction(e) {
    callDeleteFileService(e)
  }

  function setAction(passedAction) {
    if (passedAction === 'write') {
      return 'Uploaded'
    }
    else {
      return 'Downloaded'
    }
  }
  const updateRequest = (reqId, reqNotary, Status, firm) => {
    const options = {}
    const params = { 'apptReqId': reqId, 'apptNotary': reqNotary, 'reqStatus': Status, 'apptFirm': firm }
    api
      .postCreate('/accept-notary-offer', options, params)
      .then(res => setUpdateResponse(res))
      .catch(err => console.log(err))
    setShowDetails(false)
    setShowResponse(true)

  }

  const sendInvoice = (reqId) => {
    const options = {}
    const params = { 'request_id': reqId, 'action': 'sendInvoice', }
    api
      .postCreate('/manage-appts-admin', options, params)
      .then(res => setUpdateResponse(res))
      .catch(err => console.log(err))
    setShowDetails(false)
    setShowResponse(true)

  }

  return (
    <div className="card-body bg-light border-secondary">
      {showDetails && (<div>
        {requestDetails.map((items =>
          <div>
            <h5 className='card-title'>Request {items.appt_requestId} Details</h5>
            <div className='card-body'>
              <div className="row mt-3">
                <div className='col mx-3'><h6>Appointment Name:</h6></div>
                <div className='col mx-3'>{items.apptName}</div>
              </div>
              <div className="row mt-3">
                <div className='col mx-3'><h6>When:</h6></div>
                <div className='col mx-3'>{dateFormat(items.apptDate)} at {items.apptTime} {items.appt_timeZone}</div>
              </div>
              <div className="row mt-3">
                <div className='col mx-3'><h6>Where:</h6></div>
                <div className='col mx-3'>{items.apptStreet} {items.apptCity}, {items.apptState}  {items.apptZip}</div>
              </div>
              <div className="row mt-3">
                <div className='col mx-3'><h6>File Number:</h6></div>
                <div className='col mx-3'>{items.fileNumber}</div>
              </div>
              <div className="row mt-3">
                <div className='col mx-3'><h6>Lender:</h6></div>
                <div className='col mx-3'>{items.lender}</div>
              </div>
              <div className="row mt-3">
                <div className="col mx-3"><h6>Scan Backs:</h6></div>
                <div className="col mx-3">{scanBackCheck(items.scanBack)}</div>
              </div>
              <div className="row mt-3">
                <div className="col mx-3"><h6>Transaction Type:</h6></div>
                <div className="col mx-3">{setTransactionType(items.transactionType)}</div>
              </div>
              <div className="row mt-3">
                <div className="col mx-3"><h6>Financing Type:</h6></div>
                <div className="col mx-3">{setFinanceType(items.financeType)}</div>
              </div>
              <div className="row mt-3">
                <div className='col mx-3'><h6>Extra Notes:</h6></div>
                <div className='col mx-3'>{items.apptMessages}</div>
              </div>
              <div className="row mt-3">
                <div className='col mx-3'><h6>Escrow Contact:</h6></div>
                <div className='col mx-3'>{items.escrowName} - {items.escrowEmail}</div>
              </div>
              <div className="row mt-3">
                <div className='col mx-3'><h6>Signer Name:</h6></div>
                <div className='col mx-3'>{items.apptContactName}</div>
              </div>
              <div className="row mt-3">
                <div className='col mx-3'><h6>Signer Primary Phone:</h6></div>
                <div className='col mx-3'>{items.apptContactPhone1}</div>
              </div>
              <div className="row mt-3">
                <div className='col mx-3'><h6>Signer Secondary Phone:</h6></div>
                <div className='col mx-3'>{items.apptContactPhone2}</div>
              </div>
              <div className="row mt-3">
                <div className='col mx-3'><h6>Signer Primary Email:</h6></div>
                <div className='col mx-3'>{items.apptContactEmail1}</div>
              </div>
              <div className="row mt-3">
                <div className='col mx-3'><h6>Signer Secondary Email:</h6></div>
                <div className='col mx-3'>{items.apptContactEmail2}</div>
              </div>
              <div className="row mt-3">
                <div className="col mx-3"><h6>Invoice:</h6></div>
                <div className="col mx-3"><button className="list-group-item border-light bg-light text-left text-decoration-underline text-primary" title="Invoice File" type="button" onClick={() => { getInvoice(items.invoiceFileName) }}>{items.invoiceFileName}</button></div>
              </div>
              <Fragment>
              <GeneralGroupFileUpload requestId={data.requestId} clearResponseMessage={clearResponseMessage}/>
              </Fragment>
              <div className="row mt-3">
                <div className='col mx-3'><h6>File Attachment List:</h6></div>
              </div>
              <div className="card bg-light mb-2">
                {items.fileLogInfo.map(o =>
                  <div className="card-body border">
                    <div className="row">
                      <button className="btn-light btn mt-3 mb-4 mx-5" title="Delete File" id={o.fileName} style={{ backgroundImage: "url(/img/trash.svg)", backgroundSize: 'cover', backgroundRepeat: "no-repeat", width: '14px', height: '28px' }} onClick={() => deleteFile(o.fileName)}></button>
                      <button className="list-group-item border-light bg-light text-left text-decoration-underline text-primary" title="Download File" type="button" onClick={() => { getTempUrl(o.fileName) }}>{o.fileName}</button>
                    </div>
                    <div className="card-footer">
                      {o.fileReadInfo.map(info =>
                        <div> <p className="small">{setAction(info.action)} by {info.user} - {convertToSimpleDateMessage(info.loggedTime)} <GeneralUploadStatus confirm={info.confirmed} action={info.action} /></p></div>
                      )}
                    </div>
                  </div>
                )}
              </div>

              { /*
              <div className="row mt-3">
                <div className='col mx-3'><h6>Upload New Files:</h6></div>
              </div>
              <div className="border border-secondary rounded bg-white mb-3 mt-1">
                {isLoading
                  ? (<div class="d-flex justify-content-center mb-4 mt-4">
                    <div className="spinner-border" role="status">
                      <   span className="visually-hidden"></span>
                    </div>
                  </div>)
                  : (<div>{showFileName
                    ? <div className="input-group mx-2 mt-3 mb-3 col-md-5">
                      <label className="form-control bg-light text-primary" style={{ cursor: 'pointer' }} tabIndex='0'>Click to select file for upload
                        <input className="invisible" type="file" onChange={fileChangedHandler} /></label>
                    </div>
                    : <div>
                      <div className="mx-3 mt-1 mb-2">{file.name}</div>
                      <div className="mx-3 mb-1"><button className='input-group-text border-secondary' onClick={uploadHandler}>Click to Upload File</button></div></div>}</div>)}
                {showFiles && <div className="mx-3">Attached Files:
                  <div className="text-left text-decoration-underline text-primary mx-3">{file.name}<button className="list-group-item bg-light text-left mx-3 mt-1 mb-2 rounded" type="button" onClick={() => { getTempUrlCurrentFile() }}>Download file</button></div></div>} 
              </div> */}
              <Fragment>
                <AdminApptNotesHandler requestId={data.requestId} />
              </Fragment>
              {isPending && <button className='btn btn-sm btn-secondary mt-3 mb-2 rounded' type='button' onClick={() => updateRequest(items.appt_requestId, '', 'Cancel', items.appt_firm)}>Cancel Appointment</button>}
              {isScheduled && <button className='btn btn-sm btn-secondary mt-3 mb-2 rounded' type='button' onClick={() => updateRequest(items.appt_requestId, '', 'Complete', items.appt_firm)}>Mark Appointment Complete</button>}
              <button className='btn btn-sm btn-light border-secondary mx-3 mt-3 mb-2 rounded' type='button' onClick={() => showEditView(items.appt_requestId)}>Edit Request Details</button>
              <button className='btn btn-sm btn-light border-secondary mx-3 mt-3 mb-2 rounded' type='button' onClick={() => sendInvoice(items.appt_requestId)}>Generate Invoice</button>
            </div>
            <div className="card border-secondary mt-3 mb-3">
              <h5 className='card-title mt-3 mx-3'>Agent Status</h5>
              {items.notaryStatus.map((items2 =>
                <div className='card border-secondary bg-white mx-3 mb-3'>
                  <div className="card-body mt-3 mb-3">
                    <ul className='list-group list-group-flush'>
                      <li className='list-group-item'>
                        <div className='col mx-2'><h6>Signing Agent:</h6></div>
                        <div><button className='btn btn-link' type='button' onClick={() => getProfile(items2)}>{items2.apptNotary}</button></div>
                      </li>
                      <li className='list-group-item'>
                        <div className='col mx-2'><h6>Signing Agent Phone:</h6></div>
                        <div className='col mx-2'>{formatPhoneNumber(items2.notaryPhone)}</div>
                      </li>
                      <li className='list-group-item'>
                        <div className='col mx-2'><h6>Signing Agent Email:</h6></div>
                        <div className='col mx-2'>{items2.notaryEmail}</div>
                      </li>
                      <li className='list-group-item'>
                        <div className='col mx-2'><h6>Bid:</h6></div>
                        <div className='col mx-2'>{items2.apptNotaryBid}</div>
                      </li>
                      <li className='list-group-item'>
                        <div className='col mx-2'><h6>Status:</h6></div>
                        <div className='col mx-2'>{items2.apptNotaryApproval}</div>
                      </li>
                    </ul>
                    {items.apptFilled ? (<div></div>) : (<div className="row mx-3">
                      <div className="col mx-2">
                        <button className='btn btn-sm btn-secondary mt-3 mb-2 rounded' type='button' onClick={() => updateRequest(items.appt_requestId, items2.notaryEmail, 'Accept', items.appt_firm)}>Accept</button>
                      </div>
                      <div className="col nx-2">
                        <button className='btn btn-sm btn-light border-secondary mt-3 mb-2 rounded' type='button' onClick={() => updateRequest(items.appt_requestId, items2.notaryEmail, 'Decline', items.appt_firm)}>Decline</button>
                      </div></div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}</div>)
      }
      <Fragment>
        {editScreen && <AdminEditSelectChoice onMessage={handleMessage} requestDetails={requestDetails} requestId={requestId} searchStatus={data.searchStatus} />}
        {editDetails && <AdminEditCreatedRequest requestId={requestId} searchStatus={data.searchStatus} />}
        {editNotary && <AdminPickNewNotary notary={requestDetails} requestId={data.requestId} />}
        {assignDirect && <AdminAssignDirect requestId={data.requestId} />}
        {editStatus && <AdminEditStatus requestId={data.requestId} />}
        {showPopup && <PopupOverlay clearModal={clearModal} confirmAction={confirmAction} passedMessage={passedMessage} passedObject={passedObject} />}
        {showNotaryPopup && <ProfilePopupOverlay clearModal={clearModal} confirmAction={confirmAction} email={profileEmail.notaryEmail} />}
      </Fragment>

      {showResponse &&
        <div>
          {updateResponse.map((items =>
            <div>
              {items.message}
            </div>
          ))}
        </div>
      }

    </div>
  )
};

export default AdminViewRequest;