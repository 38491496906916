import React, { useState, useEffect } from 'react';
import { RestHelperNoAuth } from "../helper/restHelperNoAuth";
import mixpanel from "mixpanel-browser";
//import './app.css'; // External CSS for styling



const Autos = () => {
  const [showForm, setShowForm] = useState(false);
  const [apiRes, setApiRes] = useState('')
  const [showResponse, setShowResponse] = useState(false)
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    comments: '',
    subject: ''
  });

  useEffect(() => {
    mixpanel.track('Auto Appraisal', {'action' : 'pageview'})
}, [,])


  const handleResponse = (e) => {
    setApiRes(e)
    setShowResponse(true)
    setFormData({
        ...formData,
        ['name']: '',
        ['email']: '',
        ['phone']: '',
        ['comments']: '',
        ['subject']: ''
    })
  }
  const handleShowForm = () => {
    setShowForm(true);
    mixpanel.track('Auto Appraisal', {'action' : 'ShowForm'})
  }
  const handleCloseForm = () => setShowForm(false);
  const [error, setError] = useState('');
  const api = RestHelperNoAuth()

    // Handle form input changes
    const handleChange = (e) => {
        setFormData({
          ...formData,
          [e.target.name]: e.target.value,
        });
      };

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent page reload on form submission

    // Simple validation example (you can add more specific checks if needed)
    if (!formData.name || !formData.email || !formData.phone || ! formData.comments) {
      setError('Please fill in all required fields');
      return;
    }
    mixpanel.track('Auto Appraisal', {'action' : 'SubmitRequest'})
    try {
      // Send data to your API using fetch
      callApi()
    } catch (error) {
      // Handle network errors
      setError('There was an error submitting your request. Please try again later.');
    }
  };

  const callApi = () => {
    const options = {}
    setShowForm(false)
    api
    .postCreate('/create-contact-request', options, formData)
    .then(res => handleResponse(res))
    .catch(err => console.log(err))
}

  return (
    <div className="app-container">

      {showResponse &&
        <div className="info-section">
            <p>{apiRes}</p>
        </div>
      }
      {/* Form section (shows when CTA is clicked) */}
      {showForm && (
        <div className="form-container">
          <div className="form-header">
            <h2>Appraisal Request Form</h2>
            {/* X button to close the form */}
            <button className="close-button" onClick={() => setShowForm(false)}>X</button>
          </div>
          <form className="appraisal-form" onSubmit={handleSubmit}>
            <h6>Please fill out the below form or give us a call at <a href="tel:888-530-7592" className='phone-link'>888-530-7592</a>.</h6>
            <br/>
            <label>Name:</label>
            <input type="text" name="name" value={formData.name} onChange={handleChange} placeholder="Your Name" required />
            
            <label>Email Address:</label>
            <input type="email" name="email" value={formData.email} onChange={handleChange} placeholder="Your Email" required />
            
            <label>Callback Number:</label>
            <input type="tel" name="phone" value={formData.phone} onChange={handleChange} placeholder="Your Phone Number" required />
            
            <label>What are you wanting appraised?</label>
            <textarea name="comments" value={formData.comments} onChange={handleChange} placeholder="Describe what you want appraised and the desired timeline" />
            
            <label>City, State:</label>
            <input type="text" name="subject" value={formData.subject} onChange={handleChange} placeholder="City, State" />
            
            {/* Display error message if validation fails */}
            {error && <p className="error-message">{error}</p>}
            
            <button type="submit" className="submit-button">Submit</button>
          </form>
        </div>
      )}
      {/* Top section with headline and call-to-action */}
          <section className="hero-section">
              <div className="hero-content">
                  <h1>Schedule a Certified Auto Appraisal today!</h1>
                  <p>Get your appraisal for insurance, financial, or market value purposes.</p>
                  <p>We are nationwide and can help you wherever you are!</p>
                  <button className="cta-button" onClick={handleShowForm}>Request an Appraisal</button>
              </div>

              {/* Add sharp image to encourage scrolling */}
              <img
                  src={window.innerWidth < 768 ? "/img/mustang_cobra_small3.jpg" : "/img/mustang_cobra_large2.jpg"}
                  alt="Classic blue car that we specialize in for certified auto appraisals, ideal for insurance, financial, or market value assessment"
                  className="sharp-image"
              />
          </section>

      {/* Information section */}
      <section className="info-section">
        <h2>Why Choose Our Auto Appraisal Service?</h2>
        <p>
          Whether you need an appraisal for insurance, financial purposes, or determining the market value of your car, our certified professionals are here to help. Specializing in classic and vintage cars, we ensure your appraisal is accurate and accepted by all major institutions.
        </p>
      </section>

      {/* Customer Testimonials Section */}
      <section className="testimonials-section">
        <h2>What Our Customers Say</h2>
        <div className="testimonials">
          <blockquote>"Fantastic service! Got my classic car appraised quickly and easily." - John D.</blockquote>
          <blockquote>"Professional and reliable, highly recommended for vintage car enthusiasts!" - Sarah W.</blockquote>
        </div>
      </section>

      {/* Google Reviews Section */}
      <section className="reviews-section">
        <h2>Google Reviews</h2>
        <div className="google-reviews">
          {/* Placeholder for embedding Google Reviews */}
          <p>★★★★★</p>
          <p>"Fantastic service to work with! Great communication and integrity!" - Barbara B.</p>
        </div>
      </section>

      {/* Responsive car images */}
      <section className="car-images">
        <img
          src={window.innerWidth < 768 ? "/img/classic_car_green_small.jpg" : "/img/classic_car_green_medium.jpg"}
          alt="Classic green car that we specialize in for certified auto appraisals, ideal for insurance, financial, or market value assessment"
          className="responsive-car-image"
        />
      </section>



      {/* Footer */}
      <footer className="footer">
        <p>Contact us at: <a href="tel:888-530-7592" className='phone-link'>888-530-7592</a></p>
        <p>&copy; 2025 Pinpoint Field Services. All Rights Reserved.</p>
      </footer>
    </div>
  );
};

export default Autos;
