import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { RestHelper } from '../helper/restHelper';

const GeneralGroupFileUpload = (data) => {
  const [files, setFiles] = useState([]);
  const [isDragging, setIsDragging] = useState(false);
  const [uploadProgress, setUploadProgress] = useState({});
  const [uploading, setUploading] = useState(false);
  const [uploadInfo, setUploadInfo] = useState({})
  const [fileInfo, setFile] = useState({})
  const [isMounted, setIsMounted] = useState(false)
  const [uploadUrl, setUploadUrl] = useState({})
  const [indexTracker, setIndexTracker] = useState()
  const [uploadResponse, setUploadResponse] = useState()
  const [showLoading, setShowLoading] = useState(false)
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const env = process.env.REACT_APP_ENVIRONMENT;

  const api = RestHelper()
  const downloadIcon = '/img/file-upload-64.png';
  const loadingIcon = '/img/icons8-loading.gif'
  const dropZoneStyle1 = {
    border: '2px dashed #007bff',
    borderRadius: '10px',
    padding: '20px',
    textAlign: 'center',
    color: '#007bff',
    cursor: 'pointer',
    backgroundColor: '#f9f9f9',
  };

  const dropZoneStyle2 = {
    border: '2px solid #666',       // Medium gray border
    borderRadius: '5px',
    padding: '20px',
    textAlign: 'center',
    color: '#333',                  // Darker text color for contrast
    cursor: 'pointer',
    backgroundColor: '#ddd',        // Medium gray background
  };

  const dropZoneStyle3 = {
    border: '2px solid #ccc',
    borderRadius: '8px',
    padding: '20px',
    textAlign: 'center',
    color: '#fff',
    cursor: 'pointer',
    background: 'linear-gradient(135deg, #6d5dfc, #bb34ef)',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  };

  const browseButtonStyle = {
    display: 'inline-block',
    padding: '10px 20px',
    borderRadius: '20px',          // Fully rounded button
    backgroundColor: '#007bff',    // Button background color (Bootstrap blue)
    color: '#fff',                 // Text color
    border: 'none',
    cursor: 'pointer',
    textDecoration: 'none',        // Remove any underline from the button text
    fontSize: '16px',
    marginTop: '10px',
  };

  const browseButtonStyleDisabled = {
    display: 'inline-block',
    padding: '10px 20px',
    borderRadius: '20px',          // Fully rounded button
    backgroundColor: '#fff',    // Button background color (Bootstrap blue)
    color: '#007bff',                 // Text color
    border: 'none',
    cursor: 'pointer',
    textDecoration: 'none',        // Remove any underline from the button text
    fontSize: '16px',
    marginTop: '10px',
  };

  useEffect(() => {
    if(isMounted){
        setUploading(true)
        handleUpload()
    }
  },[files,setFiles])

 useEffect(() => {
    if (isMounted) {
    const index = indexTracker
    console.log('Current index tracker is ', index)
    console.log(uploadResponse[index].response, uploadResponse[index].file_info)      
    const formData = new FormData()
            const key = uploadResponse[index].response.fields['key']
            const keyId = uploadResponse[index].response.fields['AWSAccessKeyId']
            const policy = uploadResponse[index].response.fields['policy']
            const token = uploadResponse[index].response.fields['x-amz-security-token']
            const signature = uploadResponse[index].response.fields['signature']
            formData.append('key', key)
            formData.append('AWSAccessKeyId', keyId)
            formData.append('policy', policy)
            formData.append('x-amz-security-token', token)
            formData.append('signature', signature)
            formData.append('file', uploadResponse[index].file_info)
        
            const config = {
            onUploadProgress: (progressEvent) => {
                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                if (percentCompleted<100){
                    setUploading(true)
                }
                setUploadProgress((prevProgress) => ({
                    ...prevProgress,
                    [uploadResponse[index].file_info.name]: percentCompleted,
                }));
                if (percentCompleted===100){
                    setUploading(false)
                }
            },
        }
 
            try {
            axios.post(uploadUrl[index], formData, config);
        } catch (error) {
            console.error(`Error uploading file ${fileInfo[index].name}`, error);
        }
    }
    else {
        setIsMounted(true)
    }

 },[uploadResponse, setUploadResponse])

 function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
// Handle file drop
const onDrop = (e) => {
    e.preventDefault(); // Prevent default behavior (open file in browser)
    e.stopPropagation();
    setUploading(true)
    let droppedFiles = Array.from(e.dataTransfer.files);
    // Limit to 5 files at a time
    if (droppedFiles.length > 5) {
      alert('You can only upload up to 5 files at a time.');
    } else {
      setFiles(droppedFiles);
      setUploading(true)
      handleUpload()
    }
  };

  const setResponseInfo = (res, index, file) => {
    console.log(index, res)
    //fileUpload(res, index)
    setIndexTracker(index)
    setUploadUrl({
        ...uploadUrl,
        [index]: res.url
    })
    setUploadResponse({
        ...uploadResponse,
        [index]: {'response': res, 'file_info': file}
    })
 
    
  }

  const clearProgressBar = () => {
    setFiles([])
    setShowLoading(true)
    sleep(2000).then(() => {clearResponseMessage()});
  }

  const clearResponseMessage = () => {
    {data.clearResponseMessage()}
    setShowLoading(false)
  }

  // Handle file selection via input field
  const onFileSelect = (e) => {
    let selectedFiles = Array.from(e.target.files);
    // Limit to 5 files at a time
    if (selectedFiles.length > 5) {
      alert('You can only upload up to 5 files at a time.');
    } else {
      setFiles(selectedFiles);
    }
  };

  useEffect(() => {
    // Prevent the default behavior across the entire document to avoid file opening
    const preventDefault = (e) => {
      e.preventDefault();
      e.stopPropagation();
    };

    document.addEventListener('dragover', preventDefault);
    document.addEventListener('drop', preventDefault);

    return () => {
      document.removeEventListener('dragover', preventDefault);
      document.removeEventListener('drop', preventDefault);
    };
  }, []);

  // Prevent default behavior when dragging files over the drop zone
  const onDragOver = (e) => {
    e.preventDefault(); // Prevent default behavior (open file)
    e.stopPropagation();
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
  }
  // Get a signed URL from your AWS API
  const getSignedUrl = async (file) => {
    try {
      const response = await axios.post('/upload-file-admin', { 'unique_id': data.requestId, 'file_name': file.name });
      return response.data.url;
    } catch (error) {
      console.error('Error getting signed URL', error);
      return null;
    }
  };

  const uploadHandler = (file, index) => {
    console.log('Index is currently', index)
    const options = {}
    const fileData = {'unique_id' : data.requestId, 'file_name' : file.name }
    api
        .postCreate('/upload-file-admin', options, fileData)
        .then(res => setResponseInfo(res, index, file))
        .catch(err => console.log(err))
    return
  }

  // Upload file to S3
  const uploadFile = async (file, index) => {
    console.log(file, index)
    //setFile(file)
    setFile({
        ...fileInfo,
        [index]: file
    })
    const signedUrl = await uploadHandler(file, index);
    console.log(signedUrl)
    if (!signedUrl) return;



  };

  // Handle file upload process
  const handleUpload = async () => {
    setUploading(true);
    const promises = files.map((file, index) => uploadFile(file, index));
    await Promise.all(promises);
    //setUploading(false);
  };

  return (
    <div>
          <div className="row mt-3">
              <div className='col mx-3'><h6>Upload New Files:</h6></div>
          </div>
      {/* Drop Zone */}
      <div
            style={{
                ...dropZoneStyle2,
                backgroundColor: isDragging ? '#bbb' : '#eee', // Visual cue for drag state
              }}
        //onDrop={onDrop}
        //onDragOver={onDragOver}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={onDrop}
        //style={{
        //  border: '2px dashed #007bff',
        //  padding: '20px',
        //  textAlign: 'center',
        //  cursor: 'pointer',
        //}}
        //style={dropZoneStyle2}      
      >
  <img
    src={downloadIcon}
    alt="Download Icon"
    style={{ marginBottom: '10px', width: '48px', height: '48px' }} // Adjust size as needed
  />
        <p>Drag & Drop files here to upload (5 files max)</p>
        <p>OR</p>
        {/* Hidden file input */}
        <input
          type="file"
          multiple
          onChange={onFileSelect}
          style={{ display: 'none' }}
          id="fileInput"
          accept="*/*"
        />
        <label htmlFor="fileInput" style={{ cursor: 'pointer', color: '#007bff' }}>
          Click to Browse Files
        </label>
      </div>

      {/* Selected Files List with Progress */}
      <div style={{ marginTop: '20px' }}>
        {files.length > 0 && (
          <ul>
            {files.map((file) => (
              <li key={file.name}>
                {file.name} - {uploadProgress[file.name] || 0}%
                <div
                  style={{
                    height: '10px',
                    backgroundColor: '#007bff',
                    width: `${uploadProgress[file.name] || 0}%`,
                    marginBottom: '10px',
                  }}
                />
              </li>
            ))}
          </ul>
        )}
      </div>
      {showLoading &&
        <div>
          <img
            src={loadingIcon}
            alt="Loading Icon"
            style={{ marginBottom: '10px', width: '48px', height: '48px', marginLeft: 'auto', marginRight: 'auto', display: 'block', backgroundColor: '#007bff', }} // Adjust size as needed
          />
        </div>}
      {/* Upload Button */}
      {files.length > 0 && (
        <button onClick={clearProgressBar} disabled={uploading} style={uploading ? browseButtonStyleDisabled : browseButtonStyle}>
          {uploading ? 'Uploading...' : 'Verify Upload Status'}
        </button>
      )}
    </div>
  );
};

export default GeneralGroupFileUpload;
