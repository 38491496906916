import React, { Fragment, useEffect, useContext, useState } from "react";
import mixpanel from "mixpanel-browser";
import { NotaryNavBar, NotaryCampaign } from "../components";
import { UserContext } from "../app"

const Notary = () => {

  const {userProfile, setUser} = useContext(UserContext)
  const [display, setDisplay] = useState(false)
  const [showCampaign, setShowCampaign] = useState(false)

  useEffect(() => {
    mixpanel.track('Notary Home Page', { 'action': 'pageview' })
  }, [,])

  useEffect (() => {
    if (userProfile.profileRole === 'notary'){
      setDisplay(true)
    } 
    if (userProfile.showCampaign && sessionStorage.getItem('adClosed') === null){
      setShowCampaign(true)
    }
  },[])

  return(
  <Fragment>
    {showCampaign && <NotaryCampaign/>}
    {display 
    ? <NotaryNavBar/>
    : <div class="d-flex justify-content-center mb-4 mt-4">
        <div className="spinner-border" role="status">
          <span className="visually-hidden"></span>
        </div>
      </div>
    }
  </Fragment>)
};

export default Notary;