import {NavLink} from "react-router-dom";
import React, { Fragment } from "react";

const GuestNav = () => (
  <Fragment>
  <div className="navbar-nav mr-auto">
    <NavLink
      to="/"
      exact
      className="nav-link"
      activeClassName="router-link-exact-active"
    >
      Home
    </NavLink>
    <NavLink
      to="/about-us"
      exact
      className="nav-link"
      activeClassName="router-link-exact-active"
    >
      About Us
    </NavLink>
    <NavLink
      to="/escrow"
      exact
      className="nav-link"
      activeClassName="router-link-exact-active"
    >
      Escrow Sign Up
    </NavLink>
    <NavLink
      to="/sign-up"
      exact
      className="nav-link"
      activeClassName="router-link-exact-active"
    >
      Signing Agent / Field Inspector Sign Up
    </NavLink>
  </div>
  </Fragment>
);

export default GuestNav;
